import React from 'react';
// import Bg from '../../images/bg/hero_bg.jpg';
import Bg from '../../images/bg/hero1.png';
// import Bg from '../../images/bg/aboutus1.png'
import { Slide } from "react-awesome-reveal";
import { Link } from 'react-router-dom'

const Hero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="hero hero__style-one bg_img" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-7 mt-120">
                        <div className="hero__content">
                            <Slide direction='up' triggerOnce={'false'} duration={1200}>
                                <h1>Réalise ton rêve <br /> <span>avec DCT</span></h1>
                            </Slide>
                            <Slide direction='up' triggerOnce={'false'} duration={1400}>
                                <p style={{color: 'black', fontWeight: 'bold'}}>
                                Dream Come True Consulting est une société dédiée à la réalisation des rêves de nos clients à travers des solutions expertes dans trois domaines clés 
                                </p>
                            </Slide>
                            <ul className="xb-list list-unstyled mt-40 mb-60">
                                <Slide direction='up' triggerOnce={'false'} duration={1600}>
                                    <li><i className="far fa-check"></i>Immigration</li>
                                    <li><i className="far fa-check"></i>Organisation de voyages</li>
                                    <li><i className="far fa-check"></i>Conseils stratégiques</li>
                                </Slide>
                            </ul>
                            <Slide direction='up' triggerOnce={'false'} duration={1800}>
                                <div>
                                    <div className="btns">
                                        <a onClick={ClickHandler} className="thm-btn" href='https://calendly.com/rdv-dreamcometrueconsulting/15min' target='_blank'>Prendre Rendez-vous</a>
                                        <Link onClick={ClickHandler} className="thm-btn thm-btn--white" to="/about">Soumettre en ligne</Link>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;