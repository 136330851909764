import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import StudentFunFact from '../../components/student-visa-components/StudentFunFact/StudentFunFact';
import Teams from '../../api/team'
import PartnerSection from '../../components/PartnerSection';
import ContactSection from '../../components/ContactSection';
// import aboutImg from '../../images/about/about_img3.jpg';
import aboutImg from '../../images/bg/aboutus2.png';
import aboutImg3 from '../../images/bg/aboutus3.png';
import aboutImg4 from '../../images/bg/engaging1.png';
import aboutImg2 from '../../images/bg/mission.jpg'
// import aboutImg3 from '../../images/bg/vission.jpg'
import Bg from '../../images/bg/team_bg.jpg'
import NewsLatter from '../../components/NewsLatter/NewsLatter';
import Header3 from '../../components/header3/Header3';



const AboutUsPage = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Header3 />
            <PageTitle pageTitle={'A propos de nous'} pagesub={'A propos de nous'} />
            <section className="about pt-130">
                <div className="container">
                    <div className="row mb-40 align-items-center">
                        <div className="col-lg-6">
                            <div className="sec-title mb-20">
                                <h2 className="wow skewIn">Notre objectif, <br /> <span>réaliser vos rêves !</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="sec-title mb-20">
                                <p>
                                    Bienvenue chez Dream Come True Consulting, votre partenaire de confiance pour des expériences de voyage inoubliables. Basés en Côte d’Ivoire, nous sommes fiers de vous offrir des services spécialisés dans le domaine du tourisme,
                                    de l’assistance visa, et des études au Canada.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="xb-about__img">
                        <img style={{width: '100%', height: '700px'}} src={aboutImg} alt="" />
                    </div>
                </div>
            </section>
            <StudentFunFact />
            <section className="mission pt-130 pb-130">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse mt-none-30">
                        <div className="col-lg-6 mt-30">
                            <div className="mission__img text-lg-end">
                                <div className="inner-img d-inline-block wow skewIn" data-wow-delay="200ms">
                                    <img src={aboutImg4} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-30">
                            <div className="mission__content">
                                <div className="sec-title">
                                    <h2 className="mb-20 wow skewIn">Guider votre chemin avec notre
                                        <br /> <span>Mission d'Immigration</span></h2>
                                    <p>Notre mission chez Dream Come True Consulting, est de transcender les frontières, de connecter les cultures et de créer des moments mémorables.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-100">
                        <div className="col-lg-6 mt-30">
                            <div className="mission__img">
                                <div className="inner-img d-inline-block wow skewIn">
                                    <img style={{height: '700px'}} src={aboutImg3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-30">
                            <div className="mission__content">
                                <div className="sec-title">
                                    <h2 className="mb-20 wow skewIn" data-wow-delay="200ms">Nos valeurs
                                        <br /> <span>DCT consulting</span></h2>
                                    <p>
                                    Les valeurs fondamentales de Dream Come True Consulting sont ancrées dans notre engagement à fournir des services de haute qualité et à cultiver des relations basées sur la confiance et l'intégrité. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="team pt-120 pb-130 bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="sec-title text-center mb-65">
                        <h2 className="mb-40 wow skewIn">Our trusted immigration <br/> <span> support team</span></h2>
                        <p>At the heart of our commitment to providing exceptional <br/> immigration solutions stands</p>
                    </div>
                    <div className="row mt-none-30">
                        {Teams.slice(0, 8).map((team, tm) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mt-30" key={tm}>
                                <div className="xb-team text-center">
                                    <div className="xb-item--inner" data-parallax='{"scale" : 1}'>
                                        <div className="xb-item--img">
                                            <img src={team.tImg} alt="" />
                                        </div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--name"><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link></h3>
                                            <span className="xb-item--designation">{team.title}</span>
                                        </div>
                                        <ul className="xb-item--social list-unstyled">
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-facebook-f"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            <PartnerSection />
            <NewsLatter />
            <ContactSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutUsPage;
