import React from 'react'
// import Bg from '../../images/bg/newsletter_img.png';
import Bg from '../../images/bg/visa-canada.png';

const SubmitHandler = (e) => {
    e.preventDefault()
}


const NewsLatter = (props) => {
    return (
        <section className="newsletter">
            <div className="container">
                <div className="xb-newsletter pos-rel">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="xb-item--inner">
                                <div className="xb-item--holder mb-30">
                                    <span>newsletter</span>
                                    <h3>Abonnez-vous à la newsletter gratuite pour recevoir les dernières
                                        <br/> actualités et études de cas!
                                    </h3>
                                </div>
                                <form className="xb-item--form" onSubmit={SubmitHandler}>
                                    <input type="text" placeholder="Votre adresse e-mail"/>
                                    <button className="thm-btn">S'abonner</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="xb-newsletter__img" style={{width: '40%', marginTop: '3.5%'}}>
                        <img src={Bg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsLatter;