const Continents = [
    {
        Id: '1',
        title: 'Afrique',
    },
    {
        Id: '2',
        title: 'Europe',
    },
    {
        Id: '3',
        title: 'Asie',
    },
    {
        Id: '4',
        title: 'Amerique',
    },
    {
        Id: '5',
        title: 'Moyen-Orient',

    }
];  

export default Continents;