import swIcon1 from '../images/icon/sv_01.svg'
import swIcon2 from '../images/icon/sv_02.svg'
import swIcon3 from '../images/icon/sv_03.svg'
import swIcon4 from '../images/icon/sv_04.svg'
import swIcon5 from '../images/icon/sv_05.svg'
import swIcon6 from '../images/icon/s_icon01.svg'
import swIcon7 from '../images/icon/s_icon02.svg'
import swIcon8 from '../images/icon/s_icon03.svg'
import swIcon9 from '../images/icon/s_icon04.svg'
import swIcon10 from '../images/icon/s_icon05.svg'
import swIcon11 from '../images/icon/s_icon06.svg'
import swIcon12 from '../images/icon/s_icon07.svg'
import swIcon13 from '../images/icon/s_icon08.svg'


 
// Étude à l'étranger 
// Immigration 

const Services = [
    {
        Id: '1',
        sIcon:swIcon1,
        title: 'Organisation de voyages touristiques',
        slug: 'Tourist-Visa',
        description:"Nous organisons des visites touristiques complètes, vous permettant de découvrir de nouveaux horizons en toute tranquillité d'esprit.",
    },
    {
        Id: '2',
        sIcon:swIcon2,
        iconColor:'color2',
        title: "Organisation de voyages d'affaires",
        slug: 'Commercial-Visa',
        description:"Nous facilitons vos déplacements d'affaires, en optimisant l'efficacité et le confort de vos voyages.",
    },
    {
        Id: '3',
        sIcon:swIcon2,
        none:'d-none',
    },
    {
        Id: '4',
        sIcon:swIcon3,
        iconColor:'color3',
        title: "Étude à l'étranger",
        slug: 'Student-Visa',
        offset: 'offset-lg-4',
        description:"Nous vous aidons à étudier les options d'immigration qui correspondent le mieux à vos besoins académiques et professionnels.",
    },
    {
        Id: '5',
        sIcon:swIcon5,
        iconColor:'color5',
        title: 'Immigration',
        slug: 'Working-Visa',
        description:"Nous facilitons le processus d'immigration pour l'emploi, en vous guidant à chaque étape du chemin.",
    },
    {
        Id: '6',
        sIcon:swIcon6,
        iconColor:'color2',
        title: 'Conseils aux Entreprises et Particuliers',
        slug: 'Career-Counseling',
        description:'Nous offrons des conseils stratégiques personnalisés pour les entreprises et les particuliers, optimisant vos démarches administratives et vos stratégies de croissance.',
    },
    {
        Id: '7',
        sIcon:swIcon4,
        iconColor:'color4',
        title: "Visa Etude",
        slug: 'Etude-Visa',
        description:"Conseils d'experts pour un parcours d'immigration fluide...",
    },
    {
        Id: '8',
        sIcon:swIcon7,
        title: 'Conseils \n sur les visas',
        slug: 'Visa-Guidance',
        description:'Offrir des informations sur les exigences et les procédures de visa.',
    },
    {
        Id: '9',
        sIcon:swIcon8,
        title: 'Planification financière',
        slug: 'Financial-Planning',
        description:'Conseiller sur la démonstration de moyens financiers suffisants.',
    },
    {
        Id: '10',
        sIcon:swIcon9,
        title: 'Aide à la candidature',
        slug: 'Application-Assistance',
        description:'Aide à la compilation des documents requis.',
    },
    {
        Id: '11',
        sIcon:swIcon10,
        title: 'Préparation des documents',
        slug: 'Document-Preparation',
        description:"Aider au rassemblement tel que l'organisation nécessaire.",
    },
    {
        Id: '12',
        sIcon:swIcon11,
        title: "Conseils en matière d'assurance maladie",
        slug: 'Health-Insurance-Guidance',
        description:"Offrir des informations sur l'orientation en matière de logement et autres.",
    },
    {
        Id: '13',
        sIcon:swIcon12,
        title: 'Tests de compétence linguistique',
        slug: 'Language-Proficiency-Tests',
        description:'Fournir des conseils sur les tests de compétence linguistique requis.',
    },
    {
        Id: '14',
        sIcon:swIcon13,
        title: "Soumission et suivi des visas",
        slug: 'Visa-Submission-and-Tracking',
        description:'Aide au dépôt de la demande de visa auprès du..',
    },

]    

export default Services;