// images
import blogImg1 from "../images/blog/img_01.jpg";
import blogImg2 from "../images/blog/img_02.jpg";
import blogImg3 from "../images/blog/img_03.jpg";
import blogImg4 from "../images/blog/img_04.jpg";
import blogImg5 from "../images/blog/trv_01.jpg";
import blogImg6 from "../images/blog/trv_02.jpg";

// images
import blogsImg1 from "../images/blog/post_02.jpg";
import blogsImg2 from "../images/blog/post_01.jpg";
import blogsImg3 from "../images/blog/post_03.jpg";
import blogsImg4 from "../images/blog/img_04.jpg";
import blogsImg5 from "../images/blog/trv_01.jpg";
import blogsImg6 from "../images/blog/trv_02.jpg";


const blogs = [
    {
        id: '1',
        title: 'Cultural adjustment thriving in a new country..',
        slug:'Cultural-adjustment-thriving-in-a-new-country',
        screens: blogImg1,
        bSingle: blogsImg1,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'farugia',
        authorTitle:'Sineor Consultant',
        create_at: '16 August 2024',
        comment:'35',
        thumb:'Consulting',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Essential documents for a successful visa application..',
        slug:'Essential-documents-for-a-successful-visa-application..',
        screens: blogImg2,
        bSingle: blogsImg2,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'Andrew',
        authorTitle:'Creative Director',
        create_at: '16 September 2024',
        comment:'80',
        thumb:'Immigration',
        blClass:'format-standard-image',
        style:'color-2',
    },
    {
        id: '3',
        title: 'A guide to navigating student visas tips for success..',
        slug: 'A-guide-to-navigating-student-visas-tips-for-success..',
        screens: blogImg3,
        bSingle: blogsImg3,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'Kurtz',
        authorTitle:'Art Director',
        create_at: '22 August 2024',
        comment:'95',
        thumb:'Consulting',
        blClass:'format-video',
        style:'color-3',
    },
    {
        id: '4',
        title: '10 Reasons why you should make a physical portfolio',
        slug: '10-Reasons-why-you-should',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'Student visa consulting agencies are equipped with professionals who specialize in the intricacies of visa applications.',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: 'October 15, 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '5',
        title: 'Student Visa Consulting and the Road to Higher Education..',
        slug: 'Student-Visa-Consulting-and-the-Road-to-Higher-Education..',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'The realm of visa applications is a and ever-changing landscape...',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: '16 / 08 / 2024',
        comment:'95',
        thumb:'Study Visa',
    },
    {
        id: '6',
        title: 'How Student Visa Consulting Lights the Way..',
        slug: 'How-Student-Visa-Consulting-Lights-the-Way..',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: '16 / 08 / 2024',
        comment:'95',
        thumb:'Study Visa',
    },
    {
        id: '7',
        title: 'How Student Visa Consulting Transforms the Journey..',
        slug: 'How-Student-Visa-Consulting-Transforms-the-Journey..',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'Student visa consultants bring a wealth of knowledge to the table..',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: '16 / 08 / 2024',
        comment:'95',
        thumb:'Study Visa',
    },

    {
        id: '8',
        title: '10 Must-Visit Hidden Gems for Your Next Vacation 2024.',
        slug:'10-Must-Visit-Hidden-Gems-for-Your-Next-Vacation-2024.',
        screens: blogImg5,
        bSingle: blogsImg5,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'farugia',
        authorTitle:'Sineor Consultant',
        create_at: '16 August 2024',
        comment:'35',
        thumb:'Consulting',
        blClass:'format-standard-image',
    },
    {
        id: '9',
        title: 'From personal storytelling to traveling that will..',
        slug:'From-personal-storytelling-to-traveling-that-will',
        screens: blogImg6,
        bSingle: blogsImg6,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'Andrew',
        authorTitle:'Creative Director',
        create_at: '16 September 2024',
        comment:'80',
        thumb:'Immigration',
        blClass:'format-standard-image',
        style:'color-2',
    },
    {
        id: '10',
        title: '10 Must-Visit Hidden Gems for Your Next Vacation 2024.',
        slug:'10-Must-Visit-Hidden-Gems-for-Your-Next-Vacation-2024.',
        screens: blogImg5,
        bSingle: blogsImg5,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'farugia',
        authorTitle:'Sineor Consultant',
        create_at: '18 September 2024',
        comment:'35',
        thumb:'Consulting',
        blClass:'format-standard-image',
    },
];
export default blogs;