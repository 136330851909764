import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import icon1 from '../../images/icon/c_user.svg'
import icon2 from '../../images/icon/c_mail.svg'
import icon3 from '../../images/icon/c_select.svg'
import icon4 from '../../images/icon/c_call.svg'
import icon5 from '../../images/icon/c_message.svg'
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

const ContactForm = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [submissionState, setSubmissionState] = useState();

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = (data, e) => {
        const form = e?.target;
        e?.preventDefault();
        
        if (validator.allValid()) {
            validator.hideMessages();


            e?.persist();
            e?.preventDefault();
            
            console.log({form})
            emailjs
                .sendForm(
                    'service_cwliqmb', // service id
                    'template_u3sim8t', // template id
                    form,
                    'OVH4nrhd5EuYR3iWS' // Public Key
                )
                .then(
                    (result) => {
                        // console.log({ result });
                        // setTimeout(() => {
                        //     setIsSubmitting(false);
                            setSubmissionState(result);
                        //     // setStateMessage(null);
                        // }, 3000); // hide message after 5 seconds

                        setForms({
                            name: '',
                            email: '',
                            subject: '',
                            phone: '',
                            message: ''
                        })
                    },
                    (error) => {
                        //   setStateMessage('Something went wrong, please try again later');
                        //   setIsSubmitting(false);
                        console.log({ error });
                        setTimeout(() => {
                            // setStateMessage(null);
                        }, 5000); // hide message after 5 seconds
                    }
                );

            // Clears the form after sending the email
            e?.target.reset();


            // setForms({
            //     name: '',
            //     email: '',
            //     subject: '',
            //     phone: '',
            //     message: ''
            // })
        } else {
            validator.showMessages();
        }
    };

    return (

        <form className="xb-item--form contact-from" onSubmit={handleSubmit(submitHandler)}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon1} alt="" /></span>
                        <input
                            id='name'
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nom prenom" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon2} alt="" /></span>
                        <input
                            id='email'
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon3} alt="" /></span>
                        <select
                            id='subject'
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            className="form-control"
                            name="subject">
                            <option>Tourisme</option>
                            <option>Visa Commercial</option>
                            <option>Visa d'étudiant</option>
                            <option>Visa de travail</option>
                            <option>Assistance Visa</option>
                        </select>
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon4} alt="" /></span>
                        <input
                            id='phone'
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="xb-item--field">
                        <span><img src={icon5} alt="" /></span>
                        <textarea
                            id='message'
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                            placeholder="Ecrivez votre nessage...">
                        </textarea>
                        {validator.message('message', forms.message, 'required')}
                    </div>
                </div>
                { submissionState?.status == 200 && <div> Merci d’avoir soumis la requête. </div> }
                <div className="col-12">
                    <button className="thm-btn" type="submit" onClick={submitHandler}>Envoyer le message</button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;