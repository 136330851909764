import React from 'react';
import { Link } from 'react-router-dom'
import CountryList from '../../api/country'
import sicon2 from '../../images/icon/pdf.svg'
import sicon3 from '../../images/logo/icon.png'
import Bg from '../../images/bg/b_bg.jpg'

const ServiceSidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="sidebar-widget">
            <div className="widget">
                <ul className="widget-category list-unstyled">
                    {CountryList.slice(17, 24).map((country, cnt) => (
                        <li key={cnt}><Link onClick={ClickHandler} to={`/country-single/${country.slug}`}>{country.title}<span><img src={country.sIcon} alt=""/></span></Link></li>
                    ))}
                </ul>
            </div>
            {/* <div className="widget">
                <h3>Downloads</h3>
                <ul className="widget-download ul_li_between list-unstyled">
                    <li>
                        <Link onClick={ClickHandler} to="/contact">
                            <div className="xb-download">
                                <div className="xb-item--inner">
                                    <div className="xb-item--icon">
                                        <img src={sicon2} alt="" />
                                    </div>
                                    <h4 className="xb-item--title">TOEFL <br /> Application Form</h4>
                                    <div className="xb-item--size">
                                        3.9 KB
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={ClickHandler} to="/contact">
                            <div className="xb-download">
                                <div className="xb-item--inner">
                                    <div className="xb-item--icon">
                                        <img src={sicon2} alt="" />
                                    </div>
                                    <h4 className="xb-item--title">Terms & <br /> Conditions</h4>
                                    <div className="xb-item--size">
                                        3.9 KB
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div> */}
            <div className="widget widget-banner text-center bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                <img style={{height: '170px'}} className="mb-40" src={sicon3} alt="" />
                <h4>Approbation assurée  – <br /> 90% Garantie</h4>
                <Link onClick={ClickHandler} className="thm-btn" to="/contact">Entrer en contact</Link>
            </div>
        </div>
    )

}

export default ServiceSidebar;
