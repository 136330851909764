import React from 'react';
// import about from '../../images/about/about_img.png';
import about from '../../images/bg/billeterie.png'


const About = (props) => {

    return (
        <section className="about pos-rel pb-130">
            <div className="container">
                <div className="sec-title mb-55">
                    <h2 className="mb-30 wow skewIn">Etape à suivre & <br />
                        <span>Expertise et connaissance</span></h2>
                    <p>
                    Maintenir une expertise approfondie dans les domaines de l'immigration,

                        <br />
                        des voyages d'affaires et personnels, ainsi que des conseils aux entreprises, 
                        <br />
                        afin de fournir des conseils précis et pertinents.
                    </p>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-10">
                        <div className="about__content">
                            <ul className="about-list ul_li list-unstyled">
                                <li>
                                    <div className="xb-item--inner">
                                        <div className="xb-item--number">1</div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--title mb-10">Choisissez votre type de visa</h3>
                                            <div className="xb-item--description">
                                                Déterminez le type de visa adapté à votre voyage. Ensuite passez à l'étape suivante
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="xb-item--inner">
                                        <div className="xb-item--number color-2">2</div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--title mb-10">Contactez nos agences</h3>
                                            <div className="xb-item--description">
                                                Commencez votre demarche en faisant une demande auprès de nos agences.
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="xb-item--inner">
                                        <div className="xb-item--number color-3">3</div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--title mb-10">Soumettez tous vos documents</h3>
                                            <div className="xb-item--description">
                                                Rassemblez tous les documents requis pendant le processus.
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="xb-item--inner">
                                        <div className="xb-item--number color-4">4</div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--title mb-10">Remise du passeport</h3>
                                            <div className="xb-item--description">
                                                Recevez votre visa, qui est finalisé après demande, et voila!
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about__img" style={{width: '47%', height: '100%'}}>
                <img src={about} alt="" />
            </div>
        </section>
    )
}

export default About;