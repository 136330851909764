import React from 'react'
import { Link } from 'react-router-dom'
import pImg1 from '../../../images/package/img_01.jpg'
import pImg2 from '../../../images/package/img_02.jpg'
import pImg3 from '../../../images/package/img_03.jpg'
import pImg4 from '../../../images/package/img_04.jpg'
import pImg5 from '../../../images/package/img_05.jpg'
import pImg6 from '../../../images/package/img_06.jpg'

import fIcon1 from '../../../images/country/img_01.png'
import fIcon2 from '../../../images/country/img_02.png'
import fIcon3 from '../../../images/country/img_03.png'
import fIcon4 from '../../../images/country/img_04.png'
import fIcon5 from '../../../images/country/img_05.png'
import fIcon6 from '../../../images/country/img_06.png'

import fhIcon1 from '../../../images/icon/clock.svg'
import fhIcon2 from '../../../images/icon/user-2.svg'

import Bg from '../../../images/bg/package_bg.jpg'



const Pakage = [
    {
        title: 'Découvrez les merveilles de Paris : une escapade à la française',
        country: 'Canda',
        rating: '4.8',
        pImg: pImg1,
        flag: fIcon1,
        day: '02',
        night: '1',
        people: '10',
        price: '299 000',
    },
    {
        title: "Découvrez les trésors de l'Égypte : le voyage des pharaons",
        country: 'Belgium',
        rating: '4.5',
        pImg: pImg2,
        flag: fIcon2,
        day: '03',
        night: '2',
        people: '15',
        price: '288 000',
    },
    {
        title: 'Parcourez les rues de Rome : une odyssée italienne',
        country: 'Denmark',
        rating: '4.9',
        pImg: pImg3,
        flag: fIcon3,
        day: '05',
        night: '6',
        people: '18',
        price: '300 000',
    },
    {
        title: 'La beauté de Budapest : un séjour hongrois',
        country: 'Australia',
        rating: '4.7',
        pImg: pImg4,
        flag: fIcon4,
        day: '01',
        night: '1',
        people: '2',
        price: '150 000',
    },
    {
        title: "Charmes historiques d'Athènes : une escapade grecque",
        country: 'France',
        rating: '4.3',
        pImg: pImg5,
        flag: fIcon5,
        day: '05',
        night: '4',
        people: '18',
        price: '315 000',
    },
    {
        title: 'Paradis tropical : évasion à Bali, Indonésie',
        country: 'France',
        rating: '4.9',
        pImg: pImg6,
        flag: fIcon6,
        day: '06',
        night: '7',
        people: '12',
        price: '920 000',
    },

]


const PopularPakage = (props) => {

    return (
        <section className="package package-bg pos-rel pb-120" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="sec-title sec-title--travel text-center mb-55">
                    <span className="subtitle">No forfait</span>
                    <h2>Nos forfait populaire</h2>
                </div>
                <div className="row mt-none-30">
                    {Pakage.map((pakage, pitem) => (
                        <div className="col-lg-4 col-md-6" key={pitem}>
                            <div className="xb-package mt-30">
                                <div className="xb-item--inner">
                                    <div className="xb-item--img">
                                        <img src={pakage.pImg} alt="" />
                                    </div>
                                    <div className="xb-item--holder">
                                        <div className="xb-item--meta ul_li_between mb-15">
                                            <div className="xb-item--country ul_li">
                                                <img src={pakage.flag} alt="" />
                                                <span>{pakage.country}</span>
                                            </div>
                                            <div className="xb-item--rating ul_li">
                                                <span>{pakage.rating}</span>
                                                <ul className="xb-star-rating">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h2 className="xb-item--title"><Link to="/tourism">{pakage.title}</Link></h2>
                                        <ul className="xb-item--info ul_li_between">
                                            <li><img src={fhIcon1} alt="" /> {pakage.night} Jours {pakage.day} Nuit</li>
                                            <li><img src={fhIcon2} alt="" />{pakage.people} Personnes</li>
                                        </ul>
                                        <Link to="/tourism" className="xb-item--btn">À partir de - <span>XOF {pakage.price}</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <h2 className="xb-strock-text">Adventure world</h2>
        </section>
    )
}

export default PopularPakage;