import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import CountryList from '../../api/country'
import ServiceSidebar from './sidebar';
import NewsLatter from '../../components/NewsLatter/NewsLatter';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/footer/Footer';
import eImg1 from '../../images/country/single.jpg'
import eImg from '../../images/icon/global.svg'
import eImg2 from '../../images/icon/profile-2user-2.svg'
import eImg3 from '../../images/icon/teacher.svg'
import eImg4 from '../../images/icon/home-trend-up.svg'
// import eImg5 from '../../images/country/single2.jpg'
import eImg6 from '../../images/icon/star.svg'
import Header3 from '../../components/header3/Header3';
import eImg5 from '../../images/country/universitygraduate.jpg'

const CountrySinglePage = (props) => {
    const { slug } = useParams()

    const CountryDetails = CountryList.find(item => item.slug === slug)

    return (
        <Fragment>
            <Header3 />
            <PageTitle pageTitle={CountryDetails.title} pagesub={'Country'} />
            <section className="coaching-single pt-120 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <ServiceSidebar />
                        </div>
                        <div className="col-lg-8">
                            <div className="single-content">
                                <h3>{CountryDetails.title}</h3>

                                <p>
                                    {CountryDetails?.description}
                                </p>
                                <div>
                                    {
                                        CountryDetails?.procedure?.map(procedure => (
                                            <div style={{ marginTop: '20px' }}>
                                                <h5>{procedure?.title}</h5>
                                                <span>{procedure?.procedureDesc}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <p> It's about immersing yourself in an enriching educational experience that prepares you for global success. Here's a glimpse into what makes studying in Canada .</p> */}
                                <div className="single-img mt-35 mb-70">
                                    <img src={CountryDetails?.image ?? eImg1} alt="" />
                                </div>
                                <h3>Pourquoi nous choisir?</h3>
                                <div className="single-content__feature ul_li">
                                    <div className="single-content-feature">
                                        <div className="xb-item--inner ul_li">
                                            <div className="xb-item--icon">
                                                <img src={eImg} alt="" />
                                            </div>
                                            <h3 className="xb-item--title">Environnement favorable</h3>
                                        </div>
                                    </div>
                                    <div className="single-content-feature">
                                        <div className="xb-item--inner color-2 ul_li">
                                            <div className="xb-item--icon">
                                                <img src={eImg2} alt="" />
                                            </div>
                                            <h3 className="xb-item--title">Politiques favorables aux étudiants</h3>
                                        </div>
                                    </div>
                                    <div className="single-content-feature">
                                        <div className="xb-item--inner color-3 ul_li">
                                            <div className="xb-item--icon">
                                                <img src={eImg3} alt="" />
                                            </div>
                                            <h3 className="xb-item--title">Des enseignements supérieurs de qualité</h3>
                                        </div>
                                    </div>
                                    <div className="single-content-feature">
                                        <div className="xb-item--inner color-4 ul_li">
                                            <div className="xb-item--icon">
                                                <img src={eImg4} alt="" />
                                            </div>
                                            <h3 className="xb-item--title">Opportunités de croissance</h3>
                                        </div>
                                    </div>
                                </div>

                                <h3>Meilleure universités</h3>
                                <p>{CountryDetails?.topUniversityDesc}</p>
                                <div className="row align-items-center mt-10">
                                    <div className="col-lg-6 mt-30">
                                        <img src={eImg5} alt="" />
                                    </div>
                                    <div className="col-lg-6 mt-30">
                                        <ul className="single-content-list list-unstyled pl-25">
                                            {
                                                CountryDetails?.topUniversity?.map(university => (
                                                    <li><img src={eImg6} alt="" />{university}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewsLatter />
            <ContactSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CountrySinglePage;
