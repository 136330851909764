import timg1 from '../images/team/ceo1.png';
import timg3 from '../images/team/team1.png';
import timg2 from '../images/team/team.png';


const Teams = [
   // {
   //    Id: '1',
   //    tImg: timg1,
   //    name: 'Sara Beni',
   //    slug: 'Sara-Beni',
   //    title: 'CEO',
   // },
   {
      Id: '1',
      tImg: timg2,
      name: 'Mavi Kouadio',
      slug: 'Mavi-Kouadio',
      title: 'Assistante Exècutive / Abidjan',
   },
   {
      Id: '2',
      tImg: timg3,
      name: 'Konan Angelique',
      slug: 'konan-angelique',
      title: 'Assistante Exècutive / Montreal',
   }  
   
]

export default Teams;