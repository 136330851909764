import cnIcon1 from '../images/country/img_01.png'
import cnIcon2 from '../images/country/img_02.png'
import cnIcon3 from '../images/country/img_03.png'
import cnIcon4 from '../images/country/img_04.png'
import cnIcon5 from '../images/country/img_05.png';
import cnIcon6 from '../images/country/img_06.png'
import cnIcon7 from '../images/country/img_07.png'
import cnIcon8 from '../images/country/img_08.png'
import cnIcon9 from '../images/country/img_09.png'
import cnIcon10 from '../images/country/img_10.png'
import cnIcon11 from '../images/country/img_11.png'
import cnIcon12 from '../images/country/img_12.png'

import bigIcon1 from '../images/country/flg_01.png'
import bigIcon2 from '../images/country/flg_02.png'
import bigIcon3 from '../images/country/flg_03.png'
import bigIcon4 from '../images/country/flg_04.png'
import bigIcon5 from '../images/country/flg_05.png'
import bigIcon6 from '../images/country/flg_06.png'
import frenchflag from '../images/country/frenchflag.png'


import canadastudy from '../images/country/canadastudy.jpg'


const CountryList = [
    {
        Id: '1',
        sIcon: cnIcon1,
        title: 'Abidjan',
        slug: 'Abidjan',
    },
    {
        Id: '2',
        sIcon: bigIcon1,
        title: 'Canada',
        slug: 'Canada',
        description: `La procédure d'admission et l'obtention d'un visa pour étudier au Canada varient selon le niveau d'études et l'établissement choisi. Voici les étapes générales :`,
        procedure: [
            {
                title: "Choix de l'établissement et du programme",
                procedureDesc: "Nous vous aidons à sélectionner une université ou un collège canadien et un programme d'études qui vous intéresse."
            },
            {
                title: "Conditions d'admission",
                procedureDesc: "Nous vérifions ensemble les critères d'admission spécifiques au programme et à l'établissement. Cela peut inclure des exigences académiques, linguistiques (comme l'IELTS ou le TOEFL pour prouver votre maîtrise de l'anglais ou du français), et d'autres documents requis comme des relevés de notes et des lettres de recommandation."
            },
            {
                title: "Demande d'admission",
                procedureDesc: "Nous soumettons votre demande d'admission directement à l'établissement choisi en suivant leurs instructions spécifiques. Assurez-vous de respecter les délais."
            },
            {
                title: "Acceptation",
                procedureDesc: "Si votre demande est acceptée, l'établissement vous enverra une lettre d'acceptation (Letter of Acceptance, LOA)."
            },
            {
                title: "Obtention du visa d'étudiant",
                procedureDesc: `  
                   - Permis d'études : Vous aurez besoin d'un permis d'études pour étudier au Canada. Pour l'obtenir, une demande sera soumise auprès d'Immigration, Réfugiés et Citoyenneté Canada (IRCC).`
            }
        ],
        slug: 'Canada',
        image: canadastudy,
        topUniversityDesc: "Le paysage éducatif du Canada brille avec une constellation d'universités de premier plan qui ont acquis une reconnaissance mondiale pour leurs prouesses académiques, leurs contributions à la recherche et leur engagement envers l'innovation.",
        topUniversity: [
            "Université de Toronto",
            "Université de la Colombie-Britannique (UBC)",
            "Université McGill",
            "Université Queen's",
            "Université de Montréal"
        ]
    },
    {
        Id: '3',
        sIcon: cnIcon3,
        title: 'Belgique',
        slug: 'Belgique',
    },
    {
        Id: '5',
        sIcon: cnIcon5,
        title: 'Mali',
        slug: 'Mali',
    },
    {
        Id: '6',
        sIcon: cnIcon6,
        title: 'Allemagne',
        slug: 'Germany',
        image: canadastudy,
    },
    {
        Id: '7',
        sIcon: cnIcon7,
        title: 'Greece',
        slug: 'Greece',
    },
    {
        Id: '8',
        sIcon: cnIcon8,
        title: 'Hungary',
        slug: 'Hungary',
    },
    {
        Id: '9',
        sIcon: cnIcon9,
        title: 'Iceland',
        slug: 'Iceland',
    },
    {
        Id: '10',
        sIcon: cnIcon10,
        title: 'Ireland',
        slug: 'Ireland',
    },
    {
        Id: '11',
        sIcon: cnIcon11,
        title: 'Italy',
        slug: 'Italy',
    },
    {
        Id: '12',
        sIcon: cnIcon12,
        title: 'Luxembourg',
        slug: 'Luxembourg',
    },
    {
        Id: '13',
        sIcon: cnIcon8,
        title: 'Hungary',
        slug: 'Hungary',
    },
    {
        Id: '14',
        sIcon: cnIcon9,
        title: 'Iceland',
        slug: 'Iceland',
    },
    {
        Id: '15',
        sIcon: cnIcon10,
        title: 'Ireland',
        slug: 'Ireland',
    },
    {
        Id: '16',
        sIcon: cnIcon11,
        title: 'Italy',
        slug: 'Italy',
    },
    {
        Id: '17',
        sIcon: cnIcon12,
        title: 'Luxembourg',
        slug: 'Luxembourg',
    },
    {
        Id: '18',
        sIcon: cnIcon4,
        title: 'Australia',
        slug: 'Australia',
        image: canadastudy,
    },
    {
        Id: '19',
        sIcon: bigIcon1,
        title: 'Canada',
        slug: 'Canada',
        description: `La procédure d'admission et l'obtention d'un visa pour étudier au Canada varient selon le niveau d'études et l'établissement choisi. Voici les étapes générales :`,
        procedure: [
            {
                title: "Choix de l'établissement et du programme",
                procedureDesc: "Nous vous aidons à sélectionner une université ou un collège canadien et un programme d'études qui vous intéresse."
            },
            {
                title: "Conditions d'admission",
                procedureDesc: "Nous vérifions ensemble les critères d'admission spécifiques au programme et à l'établissement. Cela peut inclure des exigences académiques, linguistiques (comme l'IELTS ou le TOEFL pour prouver votre maîtrise de l'anglais ou du français), et d'autres documents requis comme des relevés de notes et des lettres de recommandation."
            },
            {
                title: "Demande d'admission",
                procedureDesc: "Nous soumettons votre demande d'admission directement à l'établissement choisi en suivant leurs instructions spécifiques. Assurez-vous de respecter les délais."
            },
            {
                title: "Acceptation",
                procedureDesc: "Si votre demande est acceptée, l'établissement vous enverra une lettre d'acceptation (Letter of Acceptance, LOA)."
            },
            {
                title: "Obtention du visa d'étudiant",
                procedureDesc: `  
                   - Permis d'études : Vous aurez besoin d'un permis d'études pour étudier au Canada. Pour l'obtenir, une demande sera soumise auprès d'Immigration, Réfugiés et Citoyenneté Canada (IRCC).`
            }
        ],
        slug: 'Canada',
        image: canadastudy,
        topUniversityDesc: "Le paysage éducatif du Canada brille avec une constellation d'universités de premier plan qui ont acquis une reconnaissance mondiale pour leurs prouesses académiques, leurs contributions à la recherche et leur engagement envers l'innovation.",
        topUniversity: [
            "Université de Toronto",
            "Université de la Colombie-Britannique (UBC)",
            "Université McGill",
            "Université Queen's",
            "Université de Montréal"
        ]
    },
    {
        Id: '20',
        sIcon: bigIcon2,
        title: 'Amérique',
        description: "",
        procedure: [
            {
                title: "Choisir un programme et une université",
                procedureDesc: "Nous vous aidons à sélectionnez un programme d'études et une université qui vous intéressent et qui correspondent à vos objectifs académiques et professionnels."
            },
            {
                title: "Préparation des documents",
                procedureDesc: "Nous vous aidons à rassembler les documents nécessaires tels que les relevés de notes, les résultats des tests standardisés (comme le TOEFL ou l'IELTS pour l'anglais et le SAT/ACT pour certains programmes de premier cycle), et les lettres de recommandation."
            },
            {
                title: "Tests de langue",
                procedureDesc: "Si nécessaire, passez les tests de langue requis (TOEFL ou IELTS). La plupart des universités aux États-Unis exigent une preuve de compétence en anglais pour les étudiants internationaux."
            },
            {
                title: "Demande d'admission",
                procedureDesc: "Nous vous aidons à remplir le formulaire de demande d'admission pour l'université choisie. Assurez-vous de respecter les délais d'inscription."
            }, 
            {
                title: "Financement des études",
                procedureDesc: "Nous explorons ensemble les options de financement disponibles, telles que les bourses d'études, les prêts étudiants internationaux et autres aides financières."
            },
            {
                title: "Obtention du visa",
                procedureDesc: "Une fois accepté(e) par l'université, Nous commençons la procédure pour obtenir un visa d'étudiant américain (généralement un visa F-1). Vous devrez payer les frais SEVIS et passer un entretien à l'ambassade ou au consulat américain."
            },
            {
                title: "Préparation au départ",
                procedureDesc: "Préparez-vous pour votre voyage aux États-Unis."
            },
            {
                title: "NB: Chaque université peut avoir des exigences spécifiques et des procédures supplémentaires",
                procedureDesc: ""
            }
        ],
        slug: 'America',
        image: canadastudy,
        topUniversityDesc: "",
        topUniversity: [
        ]
    },
    {
        Id: '4',
        sIcon: frenchflag,
        title: 'France',
        slug: 'France',
        // description: " Étudier en France, et plus particulièrement dans une université privée ( Bordeaux, Paris, Lyon ) implique plusieurs étapes importantes. Voici un guide DCT pour vous aider à organiser ce projet :",
        procedure: [
            // {
            //     title: "Choisir le Programme",
            //     procedureDesc: "Les ecole privée propose une large gamme de programmes dans des domaines comme le management, le marketing, la finance, la communication, etc. Identifiez le programme qui correspond à vos objectifs professionnels et académiques. Assurez-vous de vérifier les critères d'admission spécifiques à chaque programme."
            // },
            // {
            //     title: "Processus de Candidature",
            //     procedureDesc: `Préparation des Documents :* Vous aurez besoin de plusieurs documents pour votre dossier de candidature, y compris :
            //     - Votre CV
            //     - Lettre(s) de motivation
            //     - Relevés de notes et diplômes (traduits si nécessaire)
            //     - Tests de langue (comme le TOEFL, IELTS, ou le TCF/DELF si le programme est en français)
            //     - Lettres de recommandation (si requis)`
            // },
            // {
            //     title: "Candidature en ligne",
            //     procedureDesc: "La plupart des écoles privée françaises permettent une candidature en ligne. Suivez les instructions sur leur site web pour soumettre votre dossier."
            // },
            // {
            //     title: "Entretien",
            //     procedureDesc: "Certains programmes peuvent exiger un entretien, soit en personne, soit en ligne."
            // },
            // {
            //     title: "Obtenir un Visa Étudiant",
            //     procedureDesc: `Une fois que vous avez été accepté, vous devrez demander un visa étudiant (Visa de long séjour pour études). Les étapes incluent :

            //     •⁠  ⁠Prendre rendez-vous avec le Consulat de France en Côte d'Ivoire :* Vous devrez soumettre une demande de visa avec les documents suivants :
            //       - Lettre d'acceptation
            //       - Preuve de ressources financières suffisantes
            //       - Assurance maladie
            //       - Preuve de logement en France
            //       - Passeport valide`
            // },
            // {
            //     title: "Entretien et Biométrie",
            //     procedureDesc: "Vous devrez probablement passer un entretien et fournir vos empreintes digitales."
            // },
            // {
            //     title: "Logement et Assurances",
            //     procedureDesc: `•⁠  ⁠Logement : Recherchez un logement à l'avance. Les ecole privée peuvent proposer des options de logement ou des plateformes pour aider les étudiants internationaux. Les options incluent des résidences étudiantes, des appartements ou des colocations.

            //     •⁠  ⁠Assurance Santé : Vous devez avoir une assurance santé couvrant la durée de vos études en France. Les étudiants peuvent s'inscrire à la sécurité sociale étudiante une fois en France.`
            // },
            // {
            //     title: "Préparation pour le Départ",
            //     procedureDesc: `•⁠  ⁠Billet d'avion : Réservez votre vol pour arriver en France avant le début des cours.

            //     •⁠  ⁠Intégration : Familiarisez-vous avec la culture française et les attentes académiques. Les écoles comme  offrent souvent des programmes d'intégration pour les étudiants internationaux.`
            // },
            // {
            //     title: "Après l'Arrivée",
            //     procedureDesc: `•⁠  ⁠Inscription à l'école : Complétez votre inscription administrative.

            //     •⁠  ⁠Carte de Séjour : Si votre visa ne couvre pas toute la durée de vos études, vous devrez demander une carte de séjour.`
            // },
            // {
            //     title: "Planifiez bien à l'avance, car les procédures peuvent prendre du temps, surtout en ce qui concerne les demandes de visa. Bonne chance dans votre projet d'études en France!",
            //     procedureDesc: ""
            // }
        ],
        image: canadastudy,
        topUniversityDesc: "",
        topUniversity: [
        ]
    },
    {
        Id: '21',
        sIcon: bigIcon3,
        title: 'Australie',
        slug: 'Australia',
        description: "",
        procedure: [
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            }
        ],
        image: canadastudy,
    },
    {
        Id: '22',
        sIcon: bigIcon4,
        title: 'Angleterre',
        slug: 'UK',
        description: "",
        procedure: [
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            }
        ],
        image: canadastudy,
    },
    {
        Id: '23',
        sIcon: bigIcon5,
        title: 'Espagne',
        slug: 'Spain',
        description: "",
        procedure: [
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            }
        ],
        image: canadastudy,
    },
    {
        Id: '24',
        sIcon: bigIcon6,
        title: 'Allemagne',
        slug: 'Germany',
        description: "",
        procedure: [
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            },
            {
                title: "",
                procedureDesc: ""
            }
        ],
        image: canadastudy,
    },
]

export default CountryList;