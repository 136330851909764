import React, { useState } from 'react';
import Slider from "react-slick";
import logo from '../../images/icon/trustpilot.png'
import rating from '../../images/icon/tr_rattig.png'
import Bg from '../../images/bg/tm_bg.png'
import VideoModal from '../ModalVideo';
import ModalVideo from 'react-modal-video';



const testimonial = [
    {
        id: '01',
        Des: `
        Grâce à DCT j’ai pu avoir mon visa étudiant pour le Canada très rapidement en moins de 6 mois et dans de meilleurs conditions .
        Je tiens à remercier DCT pour son assistance et son son travail accompli .
        Je suis arrivé au Canada dans de meilleures conditions`,
        Title: 'Doumbia Mohammed',
        videoId: 'X7u7wOLT_kI'
    },
    {
        id: '02',
        Des: `Je me nomme ANGELIQUE SABAOTH et j’aimerai vous parler de l’agence DREAM CÔME TRUE CONSULTING qui m’a suivi pour l’obtention de mon visa canadien ,je suis vraiment très contente aujourd’hui. Je vous recommande l’agence DREAM CÔME TRUE CONSULTING pour la suivi de vos dossiers pour la demande de visa(visite / étudiant / travail )que ce soit pour le Canada la France les États Unis l’Angleterre etc… 
        Je vous recommande DCT les yeux fermés pour l’obtention de votre visa`,
        Title: 'ANGELIQUE SABAOTH ',
        videoId: 'TmHEt0JrMiU'
    },
    {
        id: '03',
        Des: `Moi c’est Coulibaly  fassely je remercie énormément l’agence DREAM COME TRUE CONSULTING pour son assistance , grace à elle j’ai pu obtenir mon visa pour le Canada et je me suis inscrit dans une école canadienne pour pouvoir réaliser mon rêve devenir joueur pro de football américain`,
        Title: 'Coulibaly Fasseri',
        videoId: 'YFDcdBGLU1w'
    },
    {
        id: '04',
        Des: `J'ai ouvert mon dossier chez DCT VOYAGE dans le mois d'Avril, en Juillet j'ai obtenu mon visa Etudiant avec une admission à l'Université de Ottawa . Je vous recommande l'agence.`,
        Title: 'KOFFI AMA RITA ABIGAEL',
        videoId: 'W1rnBuBi2J4'
    },
    {
        id: '05',
        Des: `Koffi Nguetta Fiacre, j'ai fait ma procédure avec DCT depuis la russie pour une demande visa Canada, aujourd'hui mon rêve est devenu réalité,  je vous recommande avec ma dernière énergie ce cabinet de voyage`,
        Title: 'Koffi Nguetta Fiacre',
        videoId: 'EjcJzqExFx4'
    },
    {
        id: '06',
        Des: `Merci à DCT CONSULTING par qui j'ai pu réaliser mon rêve d'étudier au Canada... filière : Pharmacie`,
        Title: 'Golly A. Colombe',
        videoId: 'CfjNE7c5vNU'
    }
];

const Testimonial = () => {
    const [isOpen, setOpen] = useState(false);
    const [videoId, setVideoId] = useState();

    const settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const slider = React.useRef(null);

    const handleOpenModal = (testimonial) => {
        setOpen(true);
        setVideoId(testimonial?.videoId);
    };

    return (
        <section className="testimonial bg_img pt-130 pb-130" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="sec-title margin-none-md mb-30-xs mb-125">
                            <h2 className="mb-70 wow skewIn">Des clients satisfaits partagent leur <br />
                                <span> expérience avec nous</span></h2>
                            <p>Embarquez pour une exploration mondiale : <br />Vivez la joie dans plus de 190 pays
                                <br /> et à travers le monde
                            </p>
                        </div>
                        <div className="xb-testimonial__nav ul_li">
                            <div className="tm-nav-item tm-button-prev" onClick={() => slider?.current?.slickPrev()}></div>
                            <div className="tm-nav-item tm-button-next" onClick={() => slider?.current?.slickNext()}></div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="xb-swiper-sliders">
                            <div className="xb-carousel-inner">
                                <div className="xb-testimonial-slider xb-swiper-container">
                                    <Slider ref={slider} {...settings}>
                                        {testimonial.map((testimonial, tsm) => (
                                            <div className="xb-testimonial" key={tsm}>
                                                <div className="xb-item--inner text-center">
                                                    <div className="xb-item--img">
                                                        {/* <img src={logo} alt="" /> */}
                                                    </div>
                                                    <div className="xb-item--content colapse">
                                                        {testimonial.Des}
                                                        {/* https://codepen.io/beedaan/pen/WpOeEz */}
                                                        {/* <a role="button" class="collapsed" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                        </a> */}
                                                    </div>
                                                    <div className="xb-item--bottom">
                                                        {/* xb-item--ratting */}
                                                        <div className="xb-item">
                                                            <button className="btn-video" onClick={() => handleOpenModal(testimonial)}><i className="far fa-play" aria-hidden="true"></i></button>
                                                            {/* <VideoModal videoId={testimonial?.videoId} /> */}
                                                            {/* <img src={rating} alt="" /> */}
                                                        </div>
                                                        <h3 className="xb-item--title">{testimonial.Title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </section>
    );
}

export default Testimonial;